<template>
  <div
    class="relative"
    :class="disabled ? 'pointer-events-none' : 'pointer-events-auto'"
  >
    <p class="text-sm-2 text-grey-light font-medium mb-0.4 relative">
      {{ label }}
    </p>
    <div
      class="flex items-center border border-grey-light-4 rounded overflow-hidden compColor"
    >
      <div
        class="w-full h-5 rounded-l cursor-pointer flex justify-end items-center"
        :style="`background-color: ${competencyColor}`"
        @click="togglePallete"
      >
        <div
          v-show="showPallete"
          class="w-1.5 h-1.5 min-w-1.5 min-h-1.5 rounded-full flex justify-center items-center mr-1 pt-0.2 checkIcon"
        >
          <img src="../../../assets/img/icons/whiteCaret.svg" />
        </div>
      </div>
      <div
        v-show="!showPallete"
        class="text-md text-center font-medium mx-1 px-0.2"
        :class="disabled ? 'text-grey-light' : 'text-grey-dark-1'"
      >
        {{ colorCode }}
      </div>
    </div>
    <div
      v-show="showPallete"
      class="mt-0.4 bg-grey-light-3 border border-grey-light-4 pallete p-1 flex flex-wrap gap-1 absolute z-10"
    >
      <div
        v-for="color in colors"
        :key="color"
        :style="`background-color: ${color}`"
        @click="() => colorClick(color)"
        class="cursor-pointer colorBlock"
      />
    </div>
  </div>
</template>
<script>
import { colors } from "../../../constants/competencyColors";

export default {
  name: "ColorPicker",
  props: {
    label: { type: String, default: null },
    competencyColor: { type: String, default: "#009999" },
    selectColor: { type: Function, default: () => {} },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    colors,
    showPallete: false,
  }),
  computed: {
    colorCode() {
      return this.competencyColor?.replace("#", "");
    },
  },
  methods: {
    togglePallete() {
      return (this.showPallete = !this.showPallete);
    },
    colorClick(color) {
      this.selectColor(color);
      this.showPallete = false;
    },
  },
};
</script>
<style lang="scss">
.pallete {
  width: 315px;
  height: 154px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
}
.colorBlock {
  width: 53px;
  height: 40px;
  border-radius: 5px;
}
.compColor {
  width: 127px;
  height: 38px;
}
.checkIcon {
  background-color: #656565;
}
</style>
