export const colors = [
  "#000000",
  "#252525",
  "#676767",
  "#004949",
  "#009999",
  "#22CF22",
  "#490092",
  "#006DDB",
  "#B66DFF",
  "#FF6DB6",
  "#920000",
  "#8F4E00",
  "#DB6D00",
  "#FFDF4D",
  "#FFFFFF",
];
