<template>
  <div>
    <div class="flex justify-between">
      <h1 class="text-xl font-headers font-bold text-grey-dark-2">
        {{ isEditMode ? "Edit" : "New" }} Competency
      </h1>
      <div class="flex">
        <Button v-if="isReadOnly" text="Back" type="primary-white" size="medium" @click.native="goBack" :iconLeft="arrow"
          rotateArrow />
        <Button v-if="!isReadOnly" class="mr-1" text="Cancel" type="secondary" size="medium" @click.native="goBack" />
        <Button v-if="!isReadOnly" text="Save" type="primary" @click.native="save" size="medium" class="px-4"
          :disabled="isSaveDisabled" />
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 py-3 mt-2.5 mb-5.5 max-h-816 overflow-y-scroll">
      <div class="max-w-1/2 min-w-600">
        <p class="font-headers font-bold text-mdl text-grey-light mb-2">
          A competency must contain at least one sub-competency.
        </p>
        <div class="ml-2.5">
          <p class="text-mdh text-grey-dark-2 mb-2">
            Enter a competency name.
          </p>
          <div class="flex gap-2.5 mb-2">
            <InputField label="Competency Name" class="w-4/5" :disabled="isReadOnly" :onChange="(value) => (name = value)"
              :defaultValue="name" />
            <ColorPicker label="Competency Color" :disabled="isReadOnly" :competencyColor="color"
              :selectColor="(value) => (color = value)" />
          </div>
          <TextArea label="Description" :disabled="isReadOnly" :onChange="(value) => (description = value)"
            :defaultValue="description" class="mb-3" />
          <div class="flex flex-col">
            <h3 class="text-md-2 text-grey-dark-1 font-bold mb-1">
              Sub-competencies
            </h3>
            <p class="text-mdh text-grey-dark-2 font-normal mb-2">
              Enter a sub-competency name.
            </p>
            <div>
              <div v-for="(item, index) in subCompetencies" :key="item.id" class="flex items-end mb-2">
                <InputField label="Sub-competency Name" :disabled="isReadOnly"
                  :onChange="(value) => (subCompetencies = { value, index })" :defaultValue="item.name" class="w-full" />
                <IconButton v-if="showDelete && !isReadOnly" :icon="remove" class="ml-2 iconButton"
                  :onClick="() => removeSubCompetency(index)" />
              </div>
            </div>
            <Button v-if="!isReadOnly" text="Add Another" type="secondary" :iconLeft="add"
              @click.native="addSubCompetency" size="medium" class="ml-auto" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isEditMode" class="bg-white px-2 pt-3 pb-4 rounded shadow mt-2">
      <p class="text-grey-light font-bold font-headers text-mdl">Status</p>
      <div class="pt-1.5 px-2">
        <p class="text-grey-dark-1 text-mdh">
          Deactivating a competency will not longer enable the company admin to
          use it when setting up an assessment.
        </p>
        <div class="flex mt-2 justify-between items-center">
          <p class="text-md" :class="status ? 'text-green' : 'text-red'">
            {{ status ? "Active" : "Inactive" }}
          </p>
          <button v-if="!isReadOnly" @click="toggleCompetency" class="text-md font-semibold" :class="status
              ? 'text-red'
              : 'text-sm-2 font-medium text-blue-dark-1 px-2 py-1 bg-blue-light-1 rounded'
            ">
            {{ status ? "Deactivate Competency" : "Activate Competency" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import add from "@/assets/img/icons/add-blue.svg";
import remove from "@/assets/img/icons/remove.svg";
import arrow from "@/assets/img/icons/arrow-right.svg";
import InputField from "../../../components/InputField/InputField.vue";
import TextArea from "../../../components/TextArea";
import ColorPicker from "../components/ColorPicker.vue";
import IconButton from "../../../components/IconButton/IconButton.vue";

export default {
  name: "NewCompetency",
  components: { InputField, ColorPicker, TextArea, IconButton },
  data: () => ({ add, remove, arrow }),
  computed: {
    ...mapState({
      competency: (state) => state.products.competency,
      productDetails: (state) => state.products.productDetails,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    isEditMode() {
      return !!this.$route.params.competencyId;
    },
    globalCompetency() {
      if (this.$route.params.productId === 'global') return true;
      return false;
    },
    backRoute() {
      if (this.globalCompetency) {
        return `/competencies`;
      }
      if (this.$route.name == "NewCompetency" && this.$route.params.productId) {
        return `/products/${this.productDetails.productId}/competencies`
      }
      return `/products/${this.productDetails.productId}/dashboard`;
    },
    showDelete() {
      return this.subCompetencies.length > 1;
    },
    isSaveDisabled() {
      return (
        this.name?.trim() === "" ||
        this.description?.trim() === "" ||
        this.color?.trim() === "" ||
        this.competency.subCompetencies.some((item) => item.name?.trim() === "")
      );
    },
    description: {
      get() {
        return this.competency.description;
      },
      set(value) {
        this.setCompetencyDescription({ description: value });
      },
    },
    name: {
      get() {
        return this.competency.name;
      },
      set(value) {
        this.setCompetencyName({ name: value });
      },
    },
    color: {
      get() {
        return this.competency.color;
      },
      set(value) {
        this.setCompetencyColor({ color: value });
      },
    },
    subCompetencies: {
      get() {
        return this.competency.subCompetencies;
      },
      set(value) {
        this.setSubCompetency(value);
      },
    },
    status: {
      get() {
        return this.competency.active;
      },
      set() {
        this.competency.active = !this.competency.active;
      },
    },
  },
  methods: {
    ...mapActions({
      setCompetencyName: "products/setCompetencyName",
      setCompetencyDescription: "products/setCompetencyDescription",
      setCompetencyColor: "products/setCompetencyColor",
      setSubCompetency: "products/setSubCompetency",
      removeSubCompetency: "products/removeSubCompetency",
      addSubCompetency: "products/addSubCompetency",
      createCompetency: "products/createCompetency",
      editCompetency: "products/editCompetency",
      deactivateCompetency: "products/deactivateCompetency",
      activateCompetency: "products/activateCompetency"
    }),
    goBack() {
      this.$router.push(this.backRoute);
    },
    async save() {
      const productId = this.$route.params.productId;
      const competencyId = this.$route.params.competencyId;
      const subCompetencies = this.subCompetencies.map((item) => {
        if (typeof item.id === "string") {
          return { name: item.name };
        } else {
          return { name: item.name, id: item.id };
        }
      });
      const payload = {
        name: this.name,
        description: this.description,
        color: this.color,
        surveyId: this.globalCompetency ? +this.competency.productId : this.productDetails.id,
        subCompetencies,
      };
      if (this.globalCompetency) {
        await this.editCompetency({ id: competencyId, payload }).then(() =>
          this.$router.push(`/competencies`)
        );
      } else {
        if (this.isEditMode) {
          await this.editCompetency({ id: competencyId, payload }).then(() =>
            this.$router.push(`/products/${productId}/dashboard`)
          );
        } else {
          await this.createCompetency(payload).then(() =>
            this.$router.push(`/products/${productId}/dashboard`)
          );
        }
      }
    },
    async toggleCompetency() {
      const competencyId = this.$route.params.competencyId;
      if (this.status) {
        await this.deactivateCompetency({ id: competencyId });
      } else {
        await this.activateCompetency({ id: competencyId });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.iconButton {
  background-color: #eff7fd;
  height: 30px;
  width: 30px;
  margin-bottom: 3px;
}
</style>
